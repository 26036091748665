<template>
  <div id="app">
    <el-container>
      <el-aside width="22%" style="padding: 20px 0px 0 0;">
         <div>
            <el-row>
              <el-col :span="24" style="margin-bottom: 10px">
                <el-page-header @back="goBack" content="历史记录"> </el-page-header>
              </el-col>
          </el-row>
        </div>
        <el-form ref="videoHistory" :rules="rules" :model="videoHistory" label-width="70px">
          <el-form-item label="设备号" prop="DevIDNO">
            <el-input
              @focus="openDialog()"
              placeholder="点击选择设备"
              v-model="videoHistory.DevIDNO"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="选择年月">
            <el-date-picker
              v-model="dateTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              @change="yymmdd()"
              align="right"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="选择时间">
            <el-time-picker
              v-model="date1"
              placeholder="开始时间点"
              value-format="HH:mm:ss"
              @change="min1"
            >
            </el-time-picker>
            <br />
            <span style="margin: 0 0 0 48%">|</span>
            <br />
            <el-time-picker
              v-model="date2"
              placeholder="结束时间点"
              value-format="HH:mm:ss"
              @change="min2"
            >
            </el-time-picker>
          </el-form-item>

          <el-form-item label="文件位置">
            <el-radio-group v-model="videoHistory.LOC">
              <el-radio :label="1">终端设备</el-radio>
              <el-radio :label="2">存储服务器</el-radio>
              <el-radio :label="4">下载服务器</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文件类型">
            <el-radio-group v-model="videoHistory.FILEATTR">
              <el-radio :label="2">录像</el-radio>
              <el-radio :label="1">图像</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="录像类型">
            <el-radio-group v-model="videoHistory.RECTYPE">
              <el-radio :label="0">常规</el-radio>
              <el-radio :label="1">报警</el-radio>
              <el-radio :label="-1">所有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签">
            <el-input v-model="videoHistory.LABEL"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submitForm('videoHistory')">搜索</el-button>
      </el-aside>
      <el-container>
        <!-- <el-header style="text-align: right; font-size: 12px"> 头 </el-header> -->
        <el-main>
          <div id="cmsv6player"></div>
          <el-table
            :data="videoData"
             height="230"
            border
            style="width: 1000px;margin-top: 10px"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="beg" label="开始时间" width="120">
            </el-table-column>
            <el-table-column prop="end" label="结束时间" width="120">
            </el-table-column>
            <el-table-column label="录像类型" prop="type" >
              <template slot-scope="scope">
                <span>{{ scope.row.type == 0 ? "常规" : "报警" }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="left" label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >
                  播放 
                </el-button
                >
                <el-button type="text" size="small" @click="download(scope.row)"
                  >
                  <a :href="scope.row.DownUrl" >
                    下载
                  </a></el-button
                >

              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      title="选择终端部设备"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree
          class="filter-tree"
          :data="device"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          @node-click="changeDevice"
          ref="tree"
        >
          <span slot-scope="{node, device}" class="span__">
            <i class="el-icon-video-camera"></i>
            <span class="tree-node-span">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import BaseData from '@/assets/config/BaseData.js';
import dataStorage from '@/../utils/dataStorage.js'

//设置视频插件的服务器ip
let serverIp = BaseData.videoIP; // 39.64.231.64
//登录服务器端口
let serverPort = BaseData.videoPort; // 6605

let swfobject;
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      
      dateTime: new Date(),
      date1: "00:00:00",
      date2: "23:59:59",

      videoData: [],
      filterText: "", //筛选
      device: [], //树形控件 查询所有设备
      defaultProps: {
        children: "son",
        label: "did",
      },
      dialogVisible: false, // 选择设备弹框
      videoHistory: {
        jsession: dataStorage.getBaseData("videoJsession"),
        DevIDNO: "", //设备号
        LOC: 1, // 文件位置
        CHN: -1, // 通道
        YEAR: "",
        MON: "",
        DAY: "",
        BEG: 0,
        END: 86399,
        RECTYPE: -1, // 录像类型
        FILEATTR: 2, // 文件类型
        LABEL: "",

        ARM1: 0,
        ARM2: 0,
        RES: 0,
        STREAM: 0,
        STORE: 0,
      },
      rules:{
        DevIDNO: [
          { required: true, message: '请选择设备', trigger: 'change' }
        ]
      },
      serverIp: serverIp,
      serverPort: serverPort,
      // 用户基本信息
      user: {
        account: "admin",
        password: "admin",
        jsession: dataStorage.getBaseData("videoJsession"), // 	会话号
      },
      // 视频配置
      videoConfig: {
        winNum: 1, // 窗口数量
        stream: 1, // 主码流
        chnNum: 0, // //指定第几通道
        winIndex: 0, //  窗口下标
        title: "",
      },
      swfobject: swfobject,
    };
  },
  created() {
    this.showDevice();
    this.getSimpleDate()
    this.yymmdd()
    this.videoHistory.DevIDNO = this.$route.query.deviceId
  },
  mounted() {
    //  初始化窗口
    this.create();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      // console.log(row,rowIndex);
      if (row.type === 1) {
        return "warning-row";
      } else if (row.type === 0) {
        return "success-row";
      }
      return "";
    },
    handleClick(row) {
      console.log(row);
      this.swfobject.startVodM(row.PlaybackUrlWs, "");
    },
    download(row) {
      console.log(row);
      axios
        .get(
          "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_addDownloadTask.action",
          {
            params: {
              jsession:this.user.jsession,
              did:row.devIdno,
              fbtm:2000 +row.year + "-" + row.mon + "-" + row.day + " " + row.beg,
              fetm:2000 +row.year + "-" + row.mon + "-" + row.day + " " + row.end,
              sbtm:2000 +row.year + "-" + row.mon + "-" + row.day + " " + row.beg,
              setm:2000 +row.year + "-" + row.mon + "-" + row.day + " " + row.end,
              lab: parseInt(new Date().getTime() / 1000) + 'a', // 标签 当前时间戳
              fph:row.file,
              vtp:row.type,
              len:row.len,
              chn:row.chn,
              dtp:1,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == 11 ) {
            this.$message.success("服务器存在该视频,开始本地下载")
          }else if (res.data.result == 0 ) {
            this.$message.success("开始下载")
          }
        });
    },
    min1(e) {
      if (e != "" && e != null) {
         // 86399
        let a = e.substring(0, 2) * 3600 + e.substring(3, 5) * 60;
        this.videoHistory.BEG = a + e.substring(6, 8) * 1;
      }
    },
    min2(e) {
      if (e != "" && e != null) {
          // 86399
        let a = e.substring(0, 2) * 3600 + e.substring(3, 5) * 60;
        this.videoHistory.END = a + e.substring(6, 8) * 1;
      }
    },
    getSimpleDate() {
      let date = new Date()
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      this.dateTime = y + '-' + m + '-' + d;
    },
    yymmdd() {
      // this.dateTime
      console.log(this.dateTime);
      if (this.dateTime != "" && this.dateTime != null) {
          this.videoHistory.YEAR = this.dateTime.substring(0, 4);
          this.videoHistory.MON = this.dateTime.substring(5, 7);
          this.videoHistory.DAY = this.dateTime.substring(8, 10);
      }
    },

    /**
     * 把秒数转化为天、时、分、秒
     * 参数value是秒数
     */
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      var dayTime = 0; // 天
      var result = "";
      if (value < 60) {
        result = "00:00:" + secondTime;
      } else {
        if (secondTime >= 60) {
          // 如果秒数大于60，将秒数转换成整数
          // 获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60);
          // 获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60);
          // 如果分钟大于60，将分钟转换成小时
          if (minuteTime >= 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
            if (hourTime >= 24) {
              // 获取天数， 获取小时除以24，得到整数天
              dayTime = parseInt(hourTime / 24);
              // 获取小时后取余小时，获取分钟除以24取余的分；
              hourTime = parseInt(hourTime % 24);
            }
          }
        }
        if (secondTime > 0) {
          secondTime =
            parseInt(secondTime) >= 10 ? secondTime : "0" + secondTime;
          result = "" + secondTime + "";
        }
        if (minuteTime > 0) {
          minuteTime =
            parseInt(minuteTime) >= 10 ? minuteTime : "0" + minuteTime;
          result = "" + minuteTime + ":" + result;
        }
        if (hourTime > 0) {
          result = "" + parseInt(hourTime) + ":" + result;
        }
        if (dayTime > 0) {
          result = "" + parseInt(dayTime) + "天" + result;
        }
      }
      return result;
    },
    create() {
      let videoOption = {
        domId: "cmsv6player", // id
        isVodMode: false, // 显示进度条,直播 false 回放 true
        width: 1000, // 窗口宽度
        height: 500, // 窗口高度
        lang: "zh", // 语言类型
      };
      // 初始化播放器
      this.swfobject = new Cmsv6Player(videoOption);
      // console.log(this.swfobject);
      //配置当前显示的窗口数目 窗口数量
      this.swfobject.setWindowNum(this.videoConfig.winNum);
      //设置视频插件的服务器ip和登录服务器端口
      this.swfobject.setServerInfo(this.serverIp, this.serverPort);
    },
    playVideo() {
      // 窗口下标 + title
      this.swfobject.setVideoInfo(
        this.videoConfig.winIndex,
        this.deviceOne.did + "-CH1"
      );
      // 播放视频  (窗口下标 + 会议号（校验Jession） + 设备号 + 指定第几通道 + 主或子码流,0:主码流,1:子码流)
      this.swfobject.startVideo(
        this.videoConfig.winIndex,
        this.user.jsession,
        this.deviceOne.did,
        this.videoConfig.chnNum,
        this.videoConfig.stream,
        true
      );
    },
    selectWarn(){
      if (this.videoHistory.BEG > this.videoHistory.END) {
            this.$message.warning("开始时间不得大于结束时间")

          }else{

            axios
              .get(
                 "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_getVideoFileInfo.action",
                {
                  params: this.videoHistory,
                }
              )
              .then((res) => {
                console.log(res);
                this.videoData = res.data.files;
                if (this.videoData == "" || this.videoData == null) {
                  this.$message.warning("该时间段无视频");
                } else {
                  this.$message.success("查询成功");
                  for (let i = 0; i < this.videoData.length; i++) {
                    this.videoData[i].beg = this.formatSeconds(this.videoData[i].beg);
                    this.videoData[i].end = this.formatSeconds(this.videoData[i].end);
                  }
                }
                // this.user.jsession = res.data.jsession
              });
          }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.selectWarn()
        }
      });
    },
    // 获取设备在线状态
    showDevice() {
      axios
        .get(
           "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_getDeviceOlStatus.action",
          {
            params: {
              jsession: dataStorage.getBaseData("videoJsession"), // 会议号
            },
          }
        )
        .then((res) => {
          // console.log(res);
          this.device = res.data.onlines;
          this.device.forEach((value, index) => {
            value["status"] = "0";
          });
          console.log("获取成功");
        });
    },

    /**
     * 选择设备弹框 方法
     */
    openDialog() {
      this.dialogVisible = true;
    },
    // 筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.vid.indexOf(value) !== -1;
    },
    changeDevice(data) {
      console.log(data);
      if (data.online == 1) {
        this.videoHistory.DevIDNO = data.did;
        this.dialogVisible = false;
        this.$message.success("选择成功");
      } else {
        this.$message.warning("设备离线");
      }
    },
    // 关闭弹框
    handleClose(done) {
          done(); 
    },
    goBack() {
      // this.$router.push("/VideoRecord");
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table .warning-row {
  background: rgb(243, 111, 71);
}
/deep/.el-table .el-table__cell {
    padding: 5px 0;
}
/deep/.el-form-item__content {
    line-height: 20px;
}
/deep/.el-radio-group {
    font-size: 0;
    display: grid;
    margin-top: 10px;
}
/deep/.el-radio, .el-radio__input {
    padding-top: 5px;
}

a {
  color: #409eff;
}
</style>
